<script setup>
import { onMounted, ref, reactive, watch } from "vue"
import Icon from "@/components/Icon"

function handleClick() {
  alert('請使用右下角的聊天視窗聯繫客服人員，將會有專人為您進行儲值手續')
}
</script>

<template>
  <div class="view-shop">
    <h1>選擇你想購買的月卡方案</h1>
    <main>
      <div class="card" @click="handleClick">
        <h2>30天方案</h2>
        <icon icon="suit-diamond-fill" />
        <p>$10,000</p>
        <button>購買月卡</button>
      </div>
      <div class="card" @click="handleClick">
        <h2>90天方案</h2>
        <icon icon="suit-heart-fill" />
        <p>$25,000</p>
        <button>購買月卡</button>
      </div>
      <div class="card" @click="handleClick">
        <h2>180天方案</h2>
        <icon icon="suit-spade-fill" />
        <p>$48,000</p>
        <button>購買月卡</button>
      </div>
    </main>
  </div>
</template>
