<script setup>
import { computed } from "vue"

const props = defineProps({
  icon: String
})
const classes = computed(() => ({ 'bi': true, 'b-icon': true, [`bi-${props.icon}`]: true }))
</script>

<template>
  <i :class="classes"></i>
</template>
