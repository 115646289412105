<script setup>
import { inject, onMounted, ref } from "vue";
import Icon from "@/components/Icon"
import LoginLogoDark from '@/assets/img/bija_logo.png';
import LoginLogo from '@/assets/img/bija_logo_w.png';
import LoadingSpinner from '@/assets/img/loading_spinning.svg';
import { useLoginStore } from "../store/login";
import { useRoute, useRouter } from "vue-router";
import moment from 'moment'

const Router = useRouter()
const Route = useRoute()
const LoginStore = useLoginStore()
const timeInterval = ref()
const timeCounter = ref(0)
const showToLogin = ref(false)
const ScreenState = inject('ScreenProvider')

onMounted(() => {
  timeInterval.value = setInterval(() => {
    if (timeCounter.value === 3) {
      showToLogin.value = true
      clearInterval(timeInterval.value)
      return
    }
    timeCounter.value += 1
  }, 1000);
})

function expCount() {
  if (LoginStore.currentUser.user_exp_at) {
    if (parseInt(moment(LoginStore.currentUser.user_exp_at*1000).diff(moment()) / 3600000 / 24) >= 0) {
      return parseInt(moment(LoginStore.currentUser.user_exp_at*1000).diff(moment()) / 3600000 / 24) + 1
    }
    return '已過期'
  }
  return '0'
}

async function logout() {
  const ensure = confirm('您確定要登出嗎')
  if (ensure) {
    await LoginStore.logout()
    Router.replace('/login')
  }
}

function closeGame() {
  const ensure = confirm('確定要結束此局嗎？')
  if (ensure) {
    Router.replace('/')
  }
}

function routerTo(path) {
  Router.push(path)
}

function newGame() {
  Router.push('/game')
}

function toLogin() {
  Router.push('/login')
}
</script>

<template>
  <Transition name="slide-top">
    <nav v-if="ScreenState.mode === 'MOBILE' && LoginStore.currentUser">
      <img :src="LoginLogoDark" alt="">
      <span class="logout" v-if="LoginStore.currentUser" @click="logout"><icon icon="door-open"/>登出</span>
    </nav>
  </Transition>
  <div class="view-home" v-if="LoginStore.currentUser">
    <header v-if="ScreenState.mode === 'COMPUTER'" >
      <div class="brand"><img :src="LoginLogo"></div>
      <div>
        <div class="day-counter" v-if="ScreenState.mode === 'COMPUTER'" >
          <button>儲值<br>時間</button>
          <div><h2>{{ expCount() }}</h2><p>日Day</p></div>
        </div>
        <div class="member"></div>
      </div>
    </header>
    <main>
      <aside v-if="ScreenState.mode === 'COMPUTER'">
        <ul>
          <li><router-link to="/game"><icon icon="suit-spade-fill" />開始新局</router-link></li>
          <li><router-link to="/shop"><icon icon="suit-club-fill" />儲值中心</router-link></li>
        </ul>
        <button @click="logout"><icon icon="door-open-fill" />登出</button>
      </aside>
      <section class="main-container">
        <template v-if="Route.fullPath!=='/'"><router-view/></template>
        <div class="home-default" v-else>
          <div>
            <p>歡迎來到</p>
            <img :src="LoginLogo">
          </div>
          <button class="new-game" @click="newGame">開始新局</button>
        </div>
      </section>
    </main>
  </div>
  <div class="view-home-loading" v-else>
    <div class="spinner-wrapper">
      <img class="spinner" :src="LoadingSpinner">
      <img class="logo" :src="LoginLogo" alt="">
    </div>
    <button v-if="showToLogin" @click="toLogin">前往登入頁</button>
  </div>
  <Transition name="slide-bottom">
    <footer v-if="ScreenState.mode === 'MOBILE' && LoginStore.currentUser">
      <div :class="{ active: Route.fullPath === '/shop' }" @click="routerTo('/shop')"><icon icon="cash-coin"/><p>儲值中心</p></div>
      <button :class="{ active: Route.name === 'Game' }" @click="() => { Route.name === 'Game' ? closeGame() : routerTo('/game') }">
        <div class="circle"><icon icon="plus-lg"/></div>
        <p>{{ Route.name === 'Game' ? '結束此局' : '開始新局' }}</p>
      </button>
      <div :class="{ active: Route.fullPath === '/' }" @click="routerTo('/')"><icon icon="person-square"/><p>會員中心</p></div>
    </footer>
  </Transition>
</template>

