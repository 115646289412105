<script setup>
import { reactive } from 'vue';
import { useLoginStore } from '../store/login';
import Icon from "@/components/Icon";
import LoginLogo from '@/assets/img/bija_logo.png';
import LoginBGImg from '@/assets/img/login_bg.png';
import { useRouter } from 'vue-router';
import { off } from 'process';

const Router = useRouter()
const LoginStore = useLoginStore()
const loginInfo = reactive({ account: "", password: "", passwordCheck: "", lineId: "", disclaimer: false })

async function signup() {
  if (!loginInfo.disclaimer) {
    return alert('請勾選同意免責聲明')
  }
  if (!loginInfo.account || !loginInfo.password || !loginInfo.passwordCheck) {
    return alert('偵測到有欄位為空，請檢查！')
  }

  if (loginInfo.password !== loginInfo.passwordCheck) {
    return alert('密碼與確認密碼不一致，請檢查！')
  }
  const res = await LoginStore.signup({ account: loginInfo.account, password: loginInfo.password, lineId: loginInfo.lineId, disclaimer: loginInfo.disclaimer })
  if (res.status === 200 && res.signupState) {
    Router.push('/login')
  } else {
    alert(res.msg)
  }
}
</script>

<template>
  <div class="view-login view-register">
    <div class="login-container">
      <h2 class="title">註冊會員</h2>
      <div class="form">
        <label for="account">
          <icon icon="person-fill"/>
          <input v-model="loginInfo.account" type="text" name="account" placeholder="帳號">
        </label>
        <label for="password">
          <icon icon="lock-fill"/>
          <input v-model="loginInfo.password" type="password" name="password" placeholder="密碼">
        </label>
        <label for="password-check">
          <icon icon="lock"/>
          <input v-model="loginInfo.passwordCheck" type="password" name="password-check" placeholder="確認密碼">
        </label>
        <label for="lineId">
          <icon icon="line"/>
          <input v-model="loginInfo.lineId" type="text" name="lineId" placeholder="Line ID">
        </label>
        <label class="checkbox" for="disclaimer">
          <input type="checkbox" v-model="loginInfo.disclaimer" name="disclaimer">我同意遵守只將此服務用於教育研究使用，否則任何損失將自行承擔
        </label>
      </div>
      <button @click="signup">註冊</button>
    </div>
    <div class="bg-container">
      <img :src="LoginBGImg">
      <div class="mask"></div>
      <div class="content">
        <h1>加入百家，成為賽局贏家</h1>
      </div>
    </div>
  </div>
</template>
