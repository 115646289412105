<script setup>
import { inject, reactive } from 'vue';
import { useLoginStore } from '../store/login';
import Icon from "@/components/Icon";
import LoginLogo from '@/assets/img/bija_logo.png';
import LoginLogoWhite from '@/assets/img/bija_logo_w.png';
import LoginBGImg from '@/assets/img/login_bg.png';
import { useRouter } from 'vue-router';

const ScreenState = inject('ScreenProvider')
const Router = useRouter()
const LoginStore = useLoginStore()
const loginInfo = reactive({ account: "", password: "" })

async function login() {
  if (!loginInfo.account || !loginInfo.password) {
    return alert('請確認電子信箱與密碼皆不為空')
  }
  const res = await LoginStore.loginWithEmail({ ...loginInfo })
  if (res.status === 200) {
    Router.push('/')
  } else {
    alert(res.msg)
  }
}

function toRegister() {
  Router.push('/register')
}
</script>

<template>
  <div class="view-login">
    <div class="login-container">
      <div class="logo">
        <p>welcome to</p>
        <img :src="ScreenState.mode === 'COMPUTER' ? LoginLogo : LoginLogoWhite">
      </div>
      <div class="form">
        <label for="account">
          <icon icon="person-fill"/>
          <input v-model="loginInfo.account"  type="text" name="account" placeholder="帳號">
        </label>
        <label for="account">
          <icon icon="lock-fill"/>
          <input v-model="loginInfo.password"  type="password" name="password" placeholder="密碼">
        </label>
        <div class="extras">
          <router-link to="/">忘記密碼</router-link>
          <router-link v-if="ScreenState.mode === 'MOBILE'" to="/register">註冊會員</router-link>
        </div>
        
      </div>
      <button @click="login">登入</button>
    </div>
    <div class="bg-container">
      <img :src="LoginBGImg">
      <div class="mask"></div>
      <div class="content">
        <h1>加入百家，成為賽局贏家</h1>
        <button @click="toRegister">立馬註冊</button>
      </div>
    </div>
  </div>
</template>

<style scope>
::placeholder {
  color: rgb(195, 195, 195);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(195, 195, 195);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:rgb(195, 195, 195);
}
</style>
