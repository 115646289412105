import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Game from '../views/Game.vue'
import Shop from '../views/Shop.vue'
import Register from '../views/Register.vue'
import { useStatisticsStore } from '../store/statistics'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/game',
        name: 'Game',
        component: Game
      },
      {
        path: '/shop',
        name: 'Shop',
        component: Shop
      },
    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
