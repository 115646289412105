import moment from 'moment'

export function euqalObject(obj1, obj2) {
  let map1 = new Map(Object.entries(obj1));
  let map2 = new Map(Object.entries(obj2));
    
  if (map1.size !== map2.size) return false;
  for (let [key, val] of map1) {
    let val2 = map2.get(key);
    if (val !== val2 || (val2 === undefined && !map2.has(key)) ) return false;
  }
  return true;
}

export function timestampParse(timestamp) {
  const stringifyTimeStamp = String(timestamp)
  if (stringifyTimeStamp.length > 10) return moment(timestamp)
  return moment(timestamp*1000)
}

export function genFormData(obj) {
  return Object.entries(obj).reduce((acc, [key, val])=> {
    if (val) {
      acc.append(key, Array.isArray(val) || (typeof val === 'object' && val !== null) ? JSON.stringify(val) : val)
    }
    return acc
  }, new FormData())
}

export function clearData(data) {
  return Object.assign({}, ...Object.entries(data).map(([key, val]) => (val && { [key]: val })))
}

export async function fetchApi(api, data={}, options={}) {
  let res;
  if (Object.keys(data).length) {
    const cleanData = clearData(data)
    const body = genFormData(cleanData)
    res = await fetch(api, { body, method: 'POST', ...options})
  } else {
    res = await fetch(api, { method: 'GET', ...options })
  }

  let text = await res.text()
  try {
    const json = JSON.parse(text)
    return json
  } catch (error) {
    console.error(error);
    console.error(`response text: ${text}`);
    return
  }
}

export async function fetchBijaApi(api, data={}, options={}) {
  const res = await fetchApi(`${process.env.VUE_APP_DEV_API_DOMAIN}${api}`, data, { headers: { 'X-Api-Key': process.env.VUE_APP_BIJA_X_API_KEY, ...options.headers }, ...options })
  return res
}
