<script setup>
import { onMounted, reactive, provide } from "vue";
import { fetchBijaApi } from "@/utils"
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import LoginLogo from '@/assets/img/bija_logo_w.png';
import { useLoginStore } from "./store/login";
import { useRoute, useRouter } from "vue-router";

const Router = useRouter()
const Route = useRoute()
const LoginStore = useLoginStore()
const screenState = reactive({
  mode: 'MOBILE'
})
provide('ScreenProvider', screenState)
let func = () => {
  if (window.innerWidth < 900) screenState.mode = 'MOBILE'
  else screenState.mode = 'COMPUTER'
}

onMounted(async () => {
  func()
  const autoLoginRes = await LoginStore.loginWithToken()
  if (autoLoginRes.status === 200) {
    if (Route.fullPath === '/login' || Route.fullPath === '/register') {
      Router.push('/')
    }
  } else {
    Router.push('/login')
  }
})
</script>

<template>
  <router-view />
</template>
