import { defineStore } from "pinia";
import { fetchBijaApi } from "../utils";

export const useLoginStore = defineStore({
  id: 'Login',
  state: () => ({
    currentUser: null,
  }),
  getters: {
  },
  actions: {
    async loginWithEmail(loginInfo) {
      const { account, password } = loginInfo
      // try {
        // const userCredential = await signInWithEmailAndPassword(auth, account, password)
        // console.log(userCredential.user);
        // const res = await fetchBijaApi(`/user/get?uid=${userCredential.user.uid}`)
        // console.log(res);
        const loginRes = await fetchBijaApi('/user/login', { account, password })
        if (loginRes.exist && loginRes.token) {
          localStorage.setItem('winbar-token', loginRes.token)
          this.currentUser = { ...loginRes.data }
        }
        return { status: 200 } 
      // } catch (error) {
      //   console.log(error.code);
      //   switch (error.code) {
      //     case 'auth/invalid-email': return { status: 400, msg: '輸入帳號格式錯誤' }
      //     case 'auth/user-not-found': return { status: 401, msg: '找不到此信箱註冊的帳戶' }
      //     case 'auth/wrong-password': return { status: 402, msg: '帳號密碼有誤，請重新確認登入資訊' }
      //     default: return { status: 500, msg: '未知錯誤請聯繫客服人員' }
      //   }
      // }
    },
    async loginWithToken() {
      const token = localStorage.getItem('winbar-token')
      if (token) {
        const res = await fetchBijaApi('/user/loginToken', { token })
        if (res.exist && res.token) {
          localStorage.setItem('winbar-token', res.token)
          this.currentUser = { ...res.data }
          return { status: 200 }
        } else {
          return { status: 422, msg: '找不到此 Token 對應的登入紀錄' } 
        }
      }
      return { status: 404, msg: 'no token' } 
    },
    // async saveUser(user) {
    //   const res = await fetchBijaApi(`/user/get?uid=${user.uid}`)
    //   if (res.exist) {
    //     this.currentUser = { ...res.data[0] }
    //   }
    //   return { status: 200 } 
    // },
    async signup(accountInfo) {
      const { account, password, lineId=undefined, disclaimer } = accountInfo
      // const userCredential = await createUserWithEmailAndPassword(auth, account, password)
      // console.log(userCredential);
      // const uid = userCredential.user.uid
      const res = await fetchBijaApi('/user/signup', { account, password, lineId, disclaimer })
      console.log(res);
      return { status: 200, ...res }
    },
    async logout() {
      localStorage.removeItem('winbar-token')
      this.currentUser = null
    }
  }
})
